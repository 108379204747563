import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { animated, useSpring } from "@react-spring/web";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
// icons
import { Send } from "react-feather";

// components
import Button from "src/components/common/Button";
import TextField from "src/components/common/TextField";

// types
import { AxiosBaseError } from "src/services/common/axiosBaseQuery/types";
import { QMetricValue } from "src/store/types/QMetrics.types";
import { Nullable } from "src/types/global";
import { EMessageSender } from "src/types/payload.types";

// hooks
import useInputReporter from "src/hooks/useInputReporter";
import { useSendOpenMessageMutation } from "src/services/interview";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useInterviewLanguage } from "src/hooks/useInterview";

import HubertChatError from "src/services/common/HubertChatError";
import rollbar from "src/services/loggers/rollbar";

// slices
import {
  moveToHistory,
  nextMessage,
  setCurrent,
  setPending,
  toggleCandidateTyping,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";
import { Reset, VisibilityChange } from "src/store/slices/QmetricSlice";

// utils
import { writeSomething } from "../common/utils";
// styles
import openQuestionInputClasses from "./openQuestionInput.module.scss";

interface Form {
  response: string;
}

const openQuestionInputSchema = z.object({
  response: z.string().nonempty("Required"),
});

const OpenQuestionInput = () => {
  const dispatch = useAppDispatch();
  const textFieldRef = useRef<Nullable<HTMLTextAreaElement>>(null);

  const [texts, setTexts] = useState<string[]>([]);
  const [units, setUnits] = useState<QMetricValue[]>([]);

  const Interview = useAppSelector((state) => state.Interview);
  const interviewLanguage = useInterviewLanguage();

  const question = useAppSelector((state) => state.Messages.current);
  const qmetrics = useAppSelector((state) => state.Qmetrics);
  const isVisible = useAppSelector(
    (state) => state.LayoutControl.globalTextInputVisibility
  );
  const [sendOpenMessage] = useSendOpenMessageMutation();
  const {
    handleKeyDown,
    handleClipboardPaste,
    handleChange,
    handleInput,
    handleKeyUp,
  } = useInputReporter();
  const openQuestionForm = useForm<Form>({
    resolver: zodResolver(openQuestionInputSchema),
    shouldFocusError: true,
  });
  const buttonSpring = useSpring({
    from: {
      transform: "scale(0)",
    },
    to: {
      transform:
        openQuestionForm.getValues("response")?.length > 0
          ? "scale(1)"
          : "scale(0)",
    },
    config: {
      duration: 150,
    },
  });
  const formRef = useRef<HTMLFormElement>(null);

  const currentQuestionMetrics = useMemo(() => {
    if (question?.id) {
      const questionIndex = Object.keys(qmetrics).findIndex(
        (m) => m === question.id
      );
      return questionIndex !== -1 ? qmetrics[question.id] : undefined;
    }
  }, [question?.id, qmetrics]);

  const handleVisibilityChange = useCallback(() => {
    const currentDate = new Date().toISOString();
    if (question?.id)
      dispatch(
        VisibilityChange({
          qutter_id: question?.id,
          visibility_change: document.hidden
            ? { left: true, date: currentDate }
            : { returned: true, date: currentDate },
        })
      );
  }, [dispatch, question?.id]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    if (isVisible && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [isVisible]);

  const handleInputSubmit = async (formData: Form) => {
    dispatch(toggleCandidateTyping({ value: false }));
    dispatch(toggleHubertTyping({ value: true }));

    if (!question) {
      toast.error("Somethign went wrong on our end. Please contact support.", {
        description: "crit err: question undefined",
      });

      rollbar.critical(
        "Critical data missing:",
        "question object is undefined."
      );
      return;
    }

    // Move user response to history
    dispatch(Reset({ qutter_id: question.id }));
    dispatch(moveToHistory());
    dispatch(
      nextMessage({
        next: {
          sender: EMessageSender.Candidate,
          answers: [`${formData.response}`],
          wait: 0,
        },
      })
    );

    const newUnits = [...units];
    const newTexts = [...texts, formData.response];

    if (currentQuestionMetrics) {
      const metrics: QMetricValue = {
        ...currentQuestionMetrics,
        current: formData.response,
        end: new Date().getTime(),
      };
      newUnits.push(metrics);
    }

    // Get new question from API and move the rest of the data to the history stack
    if (question) {
      try {
        const response = await sendOpenMessage({
          interview_id: Interview?._id,
          qutter_oid: question._id,
          qutter_id: question.id,
          language: question.language,
          expected_language: question.language,
          type: "open",
          texts: newTexts,
          units: newUnits,
        }).unwrap();

        setTexts([]);
        setUnits([]);

        dispatch(moveToHistory());

        const interviewState = response.interviewState;
        dispatch(setCurrent({
          current:
            (interviewState && interviewState.current) ??
            response.data
        }));
        dispatch(setPending({
          pending:
            (interviewState && interviewState.pending) ??
            response.pending
        }));  

        openQuestionForm.resetField("response");
      } catch (e) {
        const error = HubertChatError.safeParse(e as AxiosBaseError);
        toast.error("Failed to submit open answer", {
          description: error?.message,
        });
      }
    }
  };

  const { ref: responseFieldRef, ...openQuestionFormResponseField } =
    openQuestionForm.register("response", {
      required: true,
      onChange: (e) =>
        handleChange(e, () => {
          openQuestionForm.setValue("response", e.target.value);
        }),
    });

  if (!question?.id) {
    if (process.env.NODE_ENV === "development") {
      return <div>Question is invalid</div>;
    }

    return null;
  }

  return (
    <form
      ref={formRef}
      id="inputContainer"
      className={clsx(
        openQuestionInputClasses.openQuestionForm,
        !isVisible && "hidden"
      )}
      onSubmit={openQuestionForm.handleSubmit(handleInputSubmit)}
    >
      <div className="relative w-full">
        <TextField.Area
          {...openQuestionFormResponseField}
          id="input-text"
          ref={(e) => {
            responseFieldRef(e);
            textFieldRef.current = e;
          }}
          theme={
            openQuestionForm.formState.errors.response ? "error" : "default"
          }
          className={openQuestionInputClasses.textarea}
          placeholder={writeSomething(interviewLanguage)}
          onKeyDown={(e) => handleKeyDown(e, question.id)}
          onKeyUp={(e) =>
            handleKeyUp(e, {
              onBreakLine: () => {
                openQuestionForm.setValue(
                  "response",
                  openQuestionForm.getValues("response")
                );
                if (e.currentTarget.value.trim().length === 0 && e.key) {
                  openQuestionForm.setValue("response", "");
                }
              },
              onRequestSubmit: () => formRef.current?.requestSubmit(),
            })
          }
          onInput={(e) => handleInput(e, question.id)}
          onPaste={(e) => handleClipboardPaste(e, question.id)}
          fullWidth
          disableScrollbar
        />
      </div>
      {openQuestionForm.getValues("response")?.length > 0 && (
        <animated.div style={buttonSpring}>
          <Button
            kind="icon"
            icon={<Send />}
            type="submit"
            className={"!h-12"}
          />
        </animated.div>
      )}
    </form>
  );
};

export default OpenQuestionInput;
