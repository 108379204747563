import { forwardRef, useEffect, useRef } from "react";
import useScrollToComponent from "src/hooks/useScrollToComponent";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setGlobalTextInputVisibility } from "src/store/slices/LayoutControlSlice";
import { AddQmetrics } from "src/store/slices/QmetricSlice";

interface OpenQuestionProps {
  messageId: string;
}

const Open = forwardRef<HTMLDivElement, OpenQuestionProps>(
  ({ messageId }, _ref) => {
    const dispatch = useAppDispatch();
    const isTextInputVisible = useAppSelector(
      (state) => state.LayoutControl.globalTextInputVisibility
    );
    const openQuestionTargetRef = useRef<HTMLDivElement>(null);

    const scrollToTarget = useScrollToComponent(openQuestionTargetRef);

    useEffect(() => {
      if (isTextInputVisible) scrollToTarget();
    }, [isTextInputVisible, scrollToTarget]);

    useEffect(() => {
      dispatch(setGlobalTextInputVisibility(true));

      if (messageId) {
        dispatch(AddQmetrics({ qutter_id: messageId }));
      }

      return () => {
        dispatch(setGlobalTextInputVisibility(false));
      };
    }, [dispatch, messageId]);

    return <div ref={openQuestionTargetRef} />;
  }
);

Open.displayName = "OpenQuestion";

export default Open;
