interface BaseMessageProps {
  bubble: string;
}

const BaseMessage = ({ bubble }: BaseMessageProps) => {
  return (
    <div className="m-0" dangerouslySetInnerHTML={{ __html: bubble }}></div>
  );
};

export default BaseMessage;
