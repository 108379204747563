import { useEffect, useState } from "react";
import Header from "src/components/Header";
import InterviewIntro from "src/components/InterviewIntro";
import LoadingScreen from "src/components/LoadingScreen";
import MessageHistory from "src/components/MessageHistory";
import CurrentMessage from "src/components/Messages/CurrentMessage";
import MessageTimeProvider from "src/components/Messages/MessageTimeProvider/MessageTimeProvider";
import OpenQuestionInput from "src/components/OpenQuestionInput";
import useEnvironment from "src/hooks/useEnvironment";
import { useAppSelector } from "src/store/hooks";
import { Application, TCandidate, Job, Template } from "src/types/interview";

interface InterviewProps {
  job?:         Job;
  template?:    Template;
  candidate?:   TCandidate;
  application?: Application;
}

const Interview = ({
  job,
  template,
  candidate,
  application,
}: InterviewProps) => {
  const { env } = useEnvironment();
  const interview = useAppSelector((state) => state.Interview);
  const isGlobalInputVisible = useAppSelector(
    (state) => state.LayoutControl.globalTextInputVisibility
  );

  const [shouldRenderMessages, setShouldRenderMessages] = useState(false);

  useEffect(() => {
    if (!env.REACT_APP_HIDE_INTRO) {
      const messageRenderingTimeout = setTimeout(() => {
        setShouldRenderMessages(true);
      }, 3000);

      return () => {
        clearTimeout(messageRenderingTimeout);
      };
    } else {
      setShouldRenderMessages(true);
    }
  }, [env.REACT_APP_HIDE_INTRO]);

  if (!job && !interview && !candidate && !application) {
    return <LoadingScreen />;
  }

  return (
    <div className="messages-container scrollbar-hide">
      <Header />
      <div
        id="chatContainer"
        className="chat-container"
        data-accomodate-input={isGlobalInputVisible}
      >
        {!env.REACT_APP_HIDE_INTRO && <InterviewIntro />}
        {shouldRenderMessages && (
          <MessageTimeProvider>
            <MessageHistory />
            <CurrentMessage />
          </MessageTimeProvider>
        )}
      </div>
      <OpenQuestionInput />
    </div>
  );
};

export default Interview;
