import { ChangeEvent, ClipboardEvent, FormEvent, KeyboardEvent } from "react";
import { useAppDispatch } from "src/store/hooks";
import {
  toggleCandidateTyping,
  toggleHubertTyping,
} from "src/store/slices/MessageSlice";
import {
  Pastes,
  PushBackspace,
  PushChange,
  PushKey,
  SetCurrentKey,
} from "src/store/slices/QmetricSlice";
import { Nullable } from "src/types/global";
import useKeyDetection, { KeyCombinations } from "./useKeyDetection";

type HandleKeyUpOptions = Nullable<
  Partial<{
    onBreakLine: () => void;
    onRequestSubmit: () => void;
  }>
>;

const useInputReporter = () => {
  const { getKeyCombination } = useKeyDetection();
  const dispatch = useAppDispatch();

  const handleKeyDown = (
    e: KeyboardEvent<HTMLTextAreaElement>,
    qutter_id: string
  ) => {
    const key = e.key;
    const detectedKeyCombination = getKeyCombination(e);

    if (qutter_id) {
      dispatch(PushKey({ qutter_id, keycode: key }));
      dispatch(SetCurrentKey({ qutter_id, keycode: key }));
    }

    if (detectedKeyCombination === KeyCombinations.BACKSPACE) {
      dispatch(PushBackspace({ qutter_id }));
    }
  };

  const handleClipboardPaste = (
    e: ClipboardEvent<HTMLTextAreaElement>,
    qutter_id: string
  ) => {
    const text = e.clipboardData.getData("text");
    dispatch(Pastes({ qutter_id, text }));
  };

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    onComplete: () => void
  ) => {
    const { value } = e.target;
    let isCandidateTyping = false;

    if (value && value.length) {
      isCandidateTyping = true;
      dispatch(toggleHubertTyping({ value: false }));
    }

    dispatch(toggleCandidateTyping({ value: isCandidateTyping }));
    onComplete();
  };

  const handleInput = (e: FormEvent<HTMLTextAreaElement>, qutter_id: string) =>
    dispatch(PushChange({ qutter_id, value: e.currentTarget.value }));

  const handleKeyUp = (
    e: KeyboardEvent<HTMLTextAreaElement>,
    options?: HandleKeyUpOptions
  ) => {
    const { value } = e.currentTarget;

    if (options) {
      if (value === "\n") {
        options.onBreakLine?.();
      }
      /*
      Commented out for now (expecting revisit)
      else if (e.key === "Enter" && e.shiftKey === false) {
        options.onRequestSubmit?.();
      }
      */
    }
  };

  return {
    handleKeyDown,
    handleClipboardPaste,
    handleChange,
    handleInput,
    handleKeyUp,
  };
};

export default useInputReporter;
