import { animated } from "@react-spring/web";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import clsx from "clsx";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import {
  ChangeEvent,
  ClipboardEvent,
  createRef,
  KeyboardEvent,
  startTransition,
  useEffect,
  useMemo,
} from "react";
import { Loader, X } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "sonner";
import Button from "src/components/common/Button";
import useDataSerializer from "src/hooks/useDataSerializer";
import useOtp from "src/hooks/useOtp";
import {
  useGetOtpQuery,
  useVerifyOtpMutation,
} from "src/services/pre-interview";
import { PreInterviewCandidate } from "src/services/pre-interview/queries/types";
import { Optional } from "src/types/global";
import containerClasses from "../../container.module.scss";
import { usePreInterviewContext } from "../../PreInterviewProvider";
import { PreInterviewPageParams } from "../../types";
import useSlideSpring from "../useSlideSpring";
import otpInputClasses from "./otpStep.module.scss";

type OTP = [
  Optional<number>,
  Optional<number>,
  Optional<number>,
  Optional<number>
];

export default function OTPStep() {
  const slideAnimation = useSlideSpring();
  const navigate = useNavigate();
  const otpRefs = useMemo(
    () => Array.from({ length: 4 }).map(() => createRef<HTMLInputElement>()),
    []
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { jobId } = useParams<PreInterviewPageParams>();
  const { deserialize, serialize } = useDataSerializer();
  const candidate = useMemo(() => {
    const searchParamCandidate = searchParams.get("candidate");

    if (!searchParamCandidate) {
      return null;
    }

    const candidateData =
      deserialize<PreInterviewCandidate>(searchParamCandidate);
    return candidateData;
  }, [deserialize, searchParams]);

  const { data: otpMeta, ...getOtpQuery } = useGetOtpQuery(
    candidate?.email && jobId ? { email: candidate.email, jobId } : skipToken
  );
  const [verifyOtp, verifyOtpMutation] = useVerifyOtpMutation();

  const { setCurrentStep } = usePreInterviewContext();
  const otp = useOtp({ initialExpiry: otpMeta?.data.timeout });
  const { t } = useTranslation("preInterview");

  useEffect(() => {
    setCurrentStep(3);
  }, [setCurrentStep]);

  useEffect(() => {
    if (otpRefs[0].current) {
      otpRefs[0].current.focus();
    }
  }, [otpRefs]);

  const submitOtp = async (otpValue: OTP) => {
    try {

      if (!otpMeta?.data.state)
        throw new Error('no otp state found');

      const verifiedCandidateRes = await verifyOtp({
        state: otpMeta.data.state,
        email: candidate?.email ?? "",
        code: otpValue.join(""),
      });

      if ("data" in verifiedCandidateRes) {
        const verifiedCandidate = verifiedCandidateRes.data;

        if (verifiedCandidate.data?.token) {
          const decodedData = jwtDecode<Pick<PreInterviewCandidate, "email">>(
            verifiedCandidate.data.token
          );

          const paramCandidate = searchParams.get("candidate");

          if (paramCandidate) {
            const decodedParams =
              deserialize<PreInterviewCandidate>(paramCandidate);
            decodedParams.email = decodedData.email;
            const reEncodedParams =
              serialize<PreInterviewCandidate>(decodedParams);

            const preInterviewSearchParams = new URLSearchParams([
              ["candidate", reEncodedParams],
              ["otpToken", verifiedCandidate.data.token],
            ]);

            startTransition(() => {
              setSearchParams(preInterviewSearchParams);
            });

            navigate(
              {
                pathname: "../additional-details",
                search: `?${preInterviewSearchParams.toString()}`,
              },
              { replace: true }
            );
          }
        } else {
          otp.setError("Invalid OTP");
        }
      }
    } catch (e) {
      toast.error("Failed to verify OTP", { important: true });
    }
  };

  const handleKeyPress = (index: number) => (e: KeyboardEvent) => {
    // This prevents the user from entering anything other than numbers including e, E, +, - etc.
    if (e.key === "69") {
      e.preventDefault();
      return;
    }

    if (e.key === "Backspace") {
      otp.clearError();
      verifyOtpMutation.reset();

      const newOtp: OTP = [...otp.values];
      newOtp.splice(index, 1, undefined);
      otp.set(newOtp);

      otpRefs[index - 1]?.current?.focus();
    }
  };

  const handleValueChange =
    (otpTokenIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newOtp: OTP = [...otp.values];

      if (e.target.value !== "") {
        const value =
          e.target.value.length >= 2 ? e.target.value[1] : e.target.value;
        newOtp[otpTokenIndex] = Number(value);
        otp.set(newOtp);

        if (otpRefs[otpTokenIndex + 1]) {
          otpRefs[otpTokenIndex + 1]?.current?.focus();
        } else {
          otpRefs[otpTokenIndex].current?.blur();
        }

        if (newOtp.every((token) => token !== undefined)) {
          submitOtp(newOtp);
        }
      }
    };

  const handleInputPasteEvent = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("text");
    if (pastedData.length === 4 && !isNaN(Number(pastedData))) {
      otp.set(pastedData.split("").map((token) => parseInt(token)) as OTP);
    }
  };

  const handleOtpResetRequest = async () => {
    otp.set([undefined, undefined, undefined, undefined]);
    verifyOtpMutation.reset();
    const { data: refetchResponse } = await getOtpQuery.refetch();
    otp.countdown.restart(refetchResponse?.data.timeout);
  };

  const disableInputs =
    otp.countdown.state === "finished" ||
    verifyOtpMutation.isLoading ||
    getOtpQuery.isFetching;

  const issuedDate = dayjs.utc(otpMeta?.data.issuedAt);
  const otpCountdownTiming = dayjs
    .duration(issuedDate.add(otp.countdown.count, "seconds").diff(issuedDate))
    .format("mm:ss");

  return (
    <animated.section
      style={slideAnimation}
      className={containerClasses.container}
    >
      <section className={containerClasses.intro}>
        <h1>{t("otpStep.title")}</h1>
        <p>{t("otpStep.subtitle")}</p>
      </section>

      {getOtpQuery.isFetching ? (
        <div className={containerClasses.progressContainer}>
          <Loader size={32} className="animate-spin" />
        </div>
      ) : (
        <div className={otpInputClasses.container}>
          {Array.from({ length: 4 }).map((_, index) => (
            <input
              key={index}
              ref={otpRefs[index]}
              type="number"
              maxLength={1}
              autoComplete="one-time-code"
              value={otp.getAt(index)?.toString() ?? ""}
              placeholder="-"
              onKeyDown={handleKeyPress(index)}
              onChange={handleValueChange(index)}
              className={clsx(
                otpInputClasses.otpField,
                verifyOtpMutation.error && otpInputClasses.error
              )}
              disabled={disableInputs}
              onPaste={handleInputPasteEvent}
            />
          ))}
        </div>
      )}
      {verifyOtpMutation.error?.message && !disableInputs && (
        <div className={otpInputClasses.pageError}>
          <div className={otpInputClasses.iconContainer}>
            <X size={16} />
          </div>
          <p className="text-danger">{verifyOtpMutation.error?.message}</p>
        </div>
      )}
      <section className={containerClasses.codeStatus}>
        <div className="flex flex-col items-center gap-1">
          <p>
            {getOtpQuery.isFetching && "Sending OTP..."}
            {!getOtpQuery.isFetching &&
              (otp.countdown.state === "finished"
                ? t("otpStep.otpState.expired")
                : t("otpStep.otpState.expiring", { time: otpCountdownTiming }))}
          </p>
          <p className="text-xs text-center font-medium text-gray-400">
            {t("otpStep.checkJunk")}
          </p>
        </div>
        <button onClick={handleOtpResetRequest} hidden={getOtpQuery.isFetching}>
          {otp.countdown.count > 0
            ? t("otpStep.errors.didNotRecieve")
            : t("otpStep.errors.otpExpired")}
        </button>
      </section>
      <footer className="w-full">
        <Button
          disabled={disableInputs}
          onClick={() => submitOtp(otp.values)}
          hidden={!getOtpQuery.isFetching}
        >
          {t("otpStep.verifyOtp")}
        </Button>
        <Link
          to="../get-email"
          className="text-primary text-sm"
          onClick={() => startTransition(() => setSearchParams(undefined))}
        >
          {t("otpStep.tryAgain")}
        </Link>
      </footer>
    </animated.section>
  );
}
