import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SupportedAtsProviders } from 'src/services/job/queries/types';
import { z } from 'zod';

interface BaseRtsConfig {
  ats: (typeof SupportedAtsProviders)[keyof typeof SupportedAtsProviders];
  clientId: string;
  jobId: string;
}

const BaseRtsConfigSchema = z.object({
  ats: z.nativeEnum(SupportedAtsProviders),
  clientId: z.string(),
});

const IntelliplanRtsConfigSchema = BaseRtsConfigSchema.extend({
  ats: z.literal(SupportedAtsProviders.Intelliplan),
  title: z.string().min(1),
  jobId: z.string(),
});

type IntelliplanRtsConfig = z.infer<typeof IntelliplanRtsConfigSchema>;

type RtsConfigTypes = IntelliplanRtsConfig;

export type RtsJobConfig<T = RtsConfigTypes> = [
  config: T | null,
  errors: any
];

const useRtsJobConfig = (): RtsJobConfig => {
  const [searchParams] = useSearchParams();
  const ats = searchParams.get('ats');
  const clientId = searchParams.get('clientId');
  const jobId = searchParams.get('jobId');

  const [errors, setErrors] = useState<any>();
  const [config, setConfig] = useState<RtsConfigTypes | null>(null);

  const getIntelliplanConfig = useCallback((
    baseConfig: BaseRtsConfig,
  ) => {
    const jobTitle = searchParams.get('title');
    const jobId = searchParams.get('jobId');

    const intelliplanConfig = {
      ...baseConfig,
      ats: SupportedAtsProviders.Intelliplan,
      jobId,
      title: jobTitle,
    };

    const result = IntelliplanRtsConfigSchema.safeParse(intelliplanConfig);

    if (!result.success) {
      setErrors(result.error);
      return;
    }

    const ipConfig: IntelliplanRtsConfig = {
        ...baseConfig,
        ats: SupportedAtsProviders.Intelliplan,
        title: result.data.title,
    }

    return ipConfig;
  }, [searchParams]);

  useEffect(() => {
      if (ats === SupportedAtsProviders.Intelliplan) {
        const baseConfig: BaseRtsConfig = {
          ats: SupportedAtsProviders.Intelliplan,
          clientId: clientId!,
          jobId: jobId!,
        };
    
        const ipConfig = getIntelliplanConfig(baseConfig);

        if (ipConfig) {
          setConfig(ipConfig);
        }

      } else {
        throw new Error('Unsupported ATS');
      }
  }, [ats, clientId, getIntelliplanConfig, jobId]);


  return [
    config,
    errors,
  ];
};

export default useRtsJobConfig;
